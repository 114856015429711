<template>
  <div>
    <a-form-model @submit.prevent="$emit('submit')" spellcheck="false" ref="form" :model="form" :rules="rules">
      <b-row>
        <b-col cols="12">
          <a-form-model-item
            label="Name"
            extra="This name reflects how it appears on your site."
            name="name"
            prop="name">
            <a-input :disabled="loading" required v-model="form.name" />
          </a-form-model-item>
          <a-divider></a-divider>
          <div class="text-right">
            <b-button :disabled="loading" variant="primary" @click="submit()">
              {{ type === 'create' ? 'Add New Client' : 'Save' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </a-form-model>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'mainForm',
  props: {
    type: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: 'Please insert name' }],
      },
    }
  },
  // created() {
  //   const vm = this
  //   vm.$set(vm.form, 'portal', [vm.settings.activePortal])
  // },
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
    ...mapState('client', {
      form: state => state.form,
    }),
    loading() {
      return this.$store.state.client.loading
    },
  },
  methods: {
    submit() {
      const vm = this

      vm.$set(vm.form, 'clients', vm.client_ids)
      vm.$refs.form.validate(valid => {
        if (valid) {
          vm.$emit('submit')
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
    // Media
    mediaFinderOpen() {
      const vm = this

      vm.$bvModal.show('media-finder')
    },
  },
  destroyed() {
    this.$store.commit('client/CLEAR_DATA')
    this.$store.commit('client/CLEAR_FORM')
  },
}
</script>

<style lang="scss">
@import "./style.module.scss";
.modal {
  // z-index: 998;
}
.editor {
  line-height: normal !important;
  .ant-form-item-control {
    line-height: normal !important;
  }
}
</style>
